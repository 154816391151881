<template>
  <div>
    <h1>{{ org }}</h1>

    <c-row class="mb-4">
      <c-col md="3">
        <c-select :value.sync="lastDays" :options="lastDaysOptions" />
      </c-col>
    </c-row>

    <c-alert color="warning" class="mb-4 small border" close-button>
      <b>data caveats</b>
      <ol class="pl-3 mb-0">
        <li>Offline licenses are not tracked</li>
        <li>Minutes/concurrency data started recording in April 2021</li>
        <li>Leaving Nanome lobby open counts as minutes/concurrency data</li>
        <li>Some customer networks are blocking minutes/concurrency data</li>
        <li>
          Concurrency data counts even if additional devices were logged in for
          a short time
        </li>
      </ol>
    </c-alert>

    <template v-if="org">
      <c-row>
        <c-col md="3" class="d-flex">
          <c-card class="flex-fill">
            <c-card-header>Organization Info</c-card-header>
            <c-card-body>
              <h2>{{ info.tier || 'No Tier' }}</h2>
              <div>{{ info.licenses_purchased || 'N/A' }} license(s)</div>
            </c-card-body>
          </c-card>
        </c-col>

        <c-col v-for="stat in stats" :key="stat.title" md="3" class="d-flex">
          <c-card class="flex-fill">
            <c-card-header>{{ stat.title }}</c-card-header>
            <c-card-body>
              <template v-if="topStats[stat.data]">
                <h2>{{ topStats[stat.data].total }} {{ stat.unit }}</h2>
                <div>{{ topStats[stat.data].email }}</div>
              </template>
              <h2 v-else>{{ statusText }}</h2>
            </c-card-body>
          </c-card>
        </c-col>
      </c-row>

      <c-card v-for="card in cards" :key="card.title">
        <c-card-header>
          {{ card.title }}
          <small v-if="card.subtitle">{{ card.subtitle }}</small>
        </c-card-header>
        <c-card-body>
          <template v-if="data[card.data].length">
            <c-chart-line
              :datasets="graphs[card.data].day.datasets"
              :labels="graphs[card.data].day.labels"
              :options="getGraphOptions(card.title, 'day')"
            />

            <c-row class="mt-4">
              <c-col md="6">
                <c-chart-bar
                  :datasets="graphs[card.data].weekday.datasets"
                  :labels="graphs[card.data].weekday.labels"
                  :options="getGraphOptions('average ' + card.title, 'weekday')"
                />
              </c-col>
              <c-col md="6">
                <c-chart-horizontal-bar
                  :datasets="graphs[card.data].account.datasets"
                  :labels="graphs[card.data].account.labels"
                  :options="getGraphOptions(card.title, 'account')"
                />
              </c-col>
            </c-row>
          </template>
          <h2 v-else>{{ statusText }}</h2>
        </c-card-body>
      </c-card>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { CChartBar, CChartHorizontalBar, CChartLine } from '@coreui/vue-chartjs'
import API from '@/api'

const axesOptions = {
  gridLines: { drawOnChartArea: false },
  ticks: { beginAtZero: true }
}

const graphOptions = {
  legend: { display: false },
  scales: { xAxes: [axesOptions], yAxes: [axesOptions] },
  elements: {
    line: { cubicInterpolationMode: 'monotone' },
    point: { radius: 3 }
  },
  tooltips: { mode: 'nearest' },
  hover: { mode: 'dataset' }
}

const concurrencyValueFn = v => Math.max(+v.concurrency - 1, 0)
const loginValueFn = v => +v.count
const sessionValueFn = v => Math.round(v.seconds / 60)

export default {
  name: 'OrgAnalytics',

  props: {
    org: String
  },

  components: {
    CChartBar,
    CChartHorizontalBar,
    CChartLine
  },

  data: () => ({
    cards: [
      { title: 'Logins', data: 'logins' },
      {
        title: 'Concurrent Logins',
        subtitle: '(values are additional logged in devices)',
        data: 'concurrency'
      },
      { title: 'Minutes', data: 'sessions' }
    ],
    stats: [
      { title: 'Most Logins', data: 'loginCount', unit: 'times' },
      { title: 'Highest Total Time', data: 'totalTime', unit: 'min' },
      { title: 'Longest Average Session', data: 'avgSession', unit: 'min' }
    ],
    lastDaysOptions: [
      { label: 'last 7 days', value: 7 },
      { label: 'last 30 days', value: 30 },
      { label: 'last 90 days', value: 90 },
      { label: 'last 365 days', value: 365 },
      { label: 'all time', value: Infinity }
    ],
    info: {
      id: null,
      name: null,
      licenses_purchased: null,
      tier: null
    },
    data: {
      logins: [],
      concurrency: [],
      sessions: []
    }
  }),

  computed: {
    ...mapGetters(['loading']),

    lastDays: {
      get() {
        return this.$store.state.data.lastDays
      },
      set(value) {
        this.$store.commit('data/SET', ['lastDays', value])
      }
    },

    graphs() {
      const { logins, concurrency, sessions } = this.data
      this.$store.commit('LOAD_INCREMENT')

      const graphs = {
        concurrency: {
          account: this.graphPerAccount(concurrency, concurrencyValueFn),
          day: this.graphPerDay(concurrency, concurrencyValueFn),
          weekday: this.graphPerWeekday(concurrency, concurrencyValueFn)
        },
        logins: {
          account: this.graphPerAccount(logins, loginValueFn),
          day: this.graphPerDay(logins, loginValueFn),
          weekday: this.graphPerWeekday(logins, loginValueFn)
        },
        sessions: {
          account: this.graphPerAccount(sessions, sessionValueFn),
          day: this.graphPerDay(sessions, sessionValueFn),
          weekday: this.graphPerWeekday(sessions, sessionValueFn)
        }
      }

      this.$store.commit('LOAD_DECREMENT')
      return graphs
    },

    statusText() {
      return this.loading ? 'loading' : 'no data'
    },

    topStats() {
      return {
        loginCount: this.getTopStat(this.data.logins, loginValueFn),
        totalTime: this.getTopStat(this.data.sessions, sessionValueFn),
        avgSession: this.getTopStat(this.data.sessions, sessionValueFn, true)
      }
    }
  },

  watch: {
    org: 'refresh',
    lastDays: 'refresh'
  },

  created() {
    this.refresh()
  },

  methods: {
    async refresh() {
      this.data = {
        logins: [],
        concurrency: [],
        sessions: []
      }
      if (!this.org) return
      await this.$nextTick()

      let min_date = 0
      if (this.lastDays < Infinity) {
        min_date = moment().subtract(this.lastDays, 'days').format('YYYY-MM-DD')
      }

      this.$store.commit('LOAD_INCREMENT')

      const [info, data] = await Promise.all([
        API.getOrganizationInfo(this.org),
        API.getOrganizationData(this.org, min_date)
      ])
      this.info = Object.freeze(info.results)
      this.data = Object.freeze(data.results)

      this.$store.commit('LOAD_DECREMENT')
    },

    getGraphOptions(title, per) {
      const { lastDaysOptions, lastDays } = this
      const time = lastDaysOptions.find(l => l.value === lastDays).label
      return {
        title: {
          display: true,
          text: `${title.toLowerCase()} per ${per} (${time})`
        },
        ...graphOptions
      }
    },

    getTopStat(data, valueFn, average = false) {
      if (!data.length) return

      const totals = {}
      const counts = {}

      data.forEach(d => {
        const { email } = d
        if (!totals[email]) totals[email] = 0
        if (!counts[email]) counts[email] = 0
        totals[email] += valueFn(d)
        counts[email]++
      })

      if (average) {
        Object.keys(totals).forEach(email => {
          totals[email] = Math.round(totals[email] / counts[email])
        })
      }

      const top = Object.keys(totals).sort((a, b) => totals[b] - totals[a])[0]

      return {
        email: top,
        total: totals[top]
      }
    },

    graphPerAccount(data, valueFn) {
      if (!data.length) return

      const dataByAccount = {}
      data.forEach(d => {
        const { email } = d
        if (!dataByAccount[email]) dataByAccount[email] = 0
        dataByAccount[email] += valueFn(d)
      })

      const sorted = Object.entries(dataByAccount).sort((a, b) => b[1] - a[1])
      const labels = sorted.map(e => e[0])
      const datasets = [
        {
          data: sorted.map(e => e[1]),
          backgroundColor: '#29a1ff'
        }
      ]

      return Object.freeze({ datasets, labels })
    },

    graphPerDay(data, valueFn) {
      if (!data.length) return

      const dataByDate = {}
      data.forEach(v => {
        const date = moment(v.date).utc().format('YYYY-MM-DD')
        if (!dataByDate[date]) dataByDate[date] = {}
        dataByDate[date][v.email] = valueFn(v)
      })

      const emails = Array.from(new Set(data.map(v => v.email))).sort()
      const minDate = Object.keys(dataByDate).sort()[0]
      const days = moment().diff(minDate, 'days')

      const labels = []
      for (let i = 0; i <= days; i++) {
        labels.push(moment(minDate).add(i, 'days').format('YYYY-MM-DD'))
      }

      const datasets = []
      emails.forEach((e, i) => {
        const hue = Math.round((i / emails.length) * 360)
        const color = `hsla(${hue}, 50%, 50%, 50%)`
        const hoverColor = `hsla(${hue}, 50%, 50%, 80%)`
        const showLine = this.lastDays <= 30

        const dataset = {
          label: e,
          data: [],
          borderColor: showLine ? color : 'transparent',
          backgroundColor: hoverColor,
          hoverBorderColor: showLine ? hoverColor : 'transparent',
          hoverBorderWidth: 6,
          hoverBackgroundColor: hoverColor,
          pointHoverBorderColor: hoverColor,
          fill: false
        }

        labels.forEach(date => {
          const value = dataByDate[date] && +dataByDate[date][e]
          dataset.data.push(value || (showLine ? 0 : null))
        })

        datasets.push(dataset)
      })

      return Object.freeze({ datasets, labels })
    },

    graphPerWeekday(data, valueFn) {
      if (!data.length) return

      let minDate = moment()
      const dataByDay = Array(7).fill(0)
      data.forEach(v => {
        const date = moment(v.date)
        minDate = Math.min(minDate, date)
        const day = date.utc().day()
        dataByDay[day] += valueFn(v)
      })

      const weeks = Math.max(1, moment().diff(minDate, 'weeks'))
      Object.keys(dataByDay).forEach(day => {
        dataByDay[day] = Math.round(dataByDay[day] / weeks)
      })

      const labels = moment.weekdaysShort()
      const datasets = [
        {
          data: dataByDay,
          backgroundColor: '#29a1ff'
        }
      ]

      return Object.freeze({ datasets, labels })
    }
  }
}
</script>
